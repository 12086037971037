import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  TranslationFile,
  TranslationService,
} from 'processdelight-angular-components';
import {
  BehaviorSubject,
  Subject,
  combineLatest,
  filter,
  first,
  of,
  takeUntil,
} from 'rxjs';
import { PopupService } from 'src/app/components/popup/popup.service';
import { CoreModule } from 'src/app/core.module';
import { InputAutofocusDirective } from 'src/app/core/directives/input-autofocus.directive';
import { ResourceFunction } from 'src/app/core/models/resource/resourceFunction';
import { ResourceThing } from 'src/app/core/models/resource/resourceThing';
import { TimeSort } from 'src/app/core/models/resource/timesort';
import { timeSorts$ } from 'src/app/core/services/startup.service';
import { ResourceFacade } from 'src/app/core/store/resource/resource.facade';
import * as uuid from 'uuid';

@Component({
  selector: 'app-resource-thing-popup',
  standalone: true,
  imports: [CoreModule, InputAutofocusDirective],
  templateUrl: './resource-thing-popup.component.html',
  styleUrls: ['./resource-thing-popup.component.scss'],
})
export class ResourceThingPopupComponent implements OnInit, AfterViewInit, OnDestroy {
  destroy$ = new Subject<void>();
  resourceThings = new FormArray<FormGroup>([]);

  translations: any;
  resourceUsers = this.resourceThingFacade.resourceUsers$;
  timeSorts$ = new BehaviorSubject<TimeSort[]>([]);
  activeResourceThing: ResourceThing;
  activeResourceThingGroup?: FormGroup;

  addingNewResource = false;
  newResourceControl = new FormControl('');

  activeResourceFunction?: FormGroup;

  resourceFunctions: ResourceFunction[] = [];
  @ViewChild('machineName') machineName!: ElementRef;
  removeResourceFunctions: string[] = [];
  addedResourceFunctions: ResourceFunction[] = [];
  resourceFunctionLines: FormGroup<any>[] = [];

  constructor(
    private resourceThingFacade: ResourceFacade,
    private popup: PopupService,
    private dialogRef: MatDialogRef<ResourceThingPopupComponent>,
    private translate: TranslationService,
    @Inject(MAT_DIALOG_DATA) rt: ResourceThing,
    private cdr: ChangeDetectorRef
  ) {
    this.activeResourceThing = rt;
    this.resourceFunctions = rt?.resourceFunctions || [];
    this.translations = translate.translations;
  }
  ngAfterViewInit(): void {
    this.machineName?.nativeElement?.focus();
    this.cdr.detectChanges();
  }

  ngOnInit(): void {
    timeSorts$
      .pipe(filter((t) => !!t))
      .subscribe((t) => this.timeSorts$.next(t!));

    this.resourceThingFacade.allResourceThings$
      .pipe(
        takeUntil(this.destroy$),
        filter((rt) => !!rt)
      )
      .subscribe((resourceThings) => {
        this.activeResourceThing = resourceThings?.find(
          (rt) => rt.id === this.activeResourceThing?.id
        ) || this.activeResourceThing;
        this.initForms();
      });
  }

  initForms() {
    this.activeResourceThingGroup = new FormGroup({
      id: new FormControl({
        value: this.activeResourceThing?.id,
        disabled: false,
      }),
      name: new FormControl({
        value: this.activeResourceThing?.name,
        disabled: false,
      }, [Validators.required]),
      resourceFunctionLines: new FormArray(
        this.activeResourceThing?.resourceFunctions?.map((e) => {
          const resourceFunctionLine = new FormGroup({
            id: new FormControl({
              value: e.id,
              disabled: false,
            }),
            name: new FormControl({
              value: e.name,
              disabled: false,
            }),
            description: new FormControl({
              value: e.description,
              disabled: false,
            }),
            capacity: new FormControl({
              value: e.capacity,
              disabled: false,
            }),
            timeAmount: new FormControl({
              value: e.timeAmount,
              disabled: false,
            }),
            timeSortId: new FormControl({
              value: e.timeSortId || '',
              disabled: false,
            }),
          });
          if (this.activeResourceFunction?.value.id === e.id)
            this.activeResourceFunction = resourceFunctionLine;
          return resourceFunctionLine;
        }) || []
      ),
    });
    // if (this.activeResourceFunction)
    //   this.activeResourceFunction =
    //     this.getThingResourceFunctionLineGroups().find(
    //       (g) =>
    //         g.value.id === this.activeResourceFunction?.value.id
    //     );
    this.resourceFunctionLines = (
      this.activeResourceThingGroup?.controls[
      'resourceFunctionLines'
      ] as FormArray
    ).controls as FormGroup[];
    this.activeResourceFunction = this.resourceFunctionLines[0];
  }

  selectFunc(fg?: FormGroup) {
    this.activeResourceFunction = fg;
  }

  getResourceFunction(fg: FormGroup) {
    return new ResourceFunction({
      ...fg,
      timeSortId: this.activeResourceFunction?.value.timeSortId,
    });
  }

  addNewFunction() {
    const resourceFunction = new ResourceFunction({
      id: uuid.v4(),
      name: '',
      description: '',
      capacity: 0,
      timeAmount: 0,
      // time sort id is not set -> post error
      timeSortId: this.timeSorts$.value[0].id,
      resourceThingId: this.activeResourceThingGroup?.value.id || '',
    });
    const activeResourceFunctionFormGroup =
      new FormGroup({
        id: new FormControl({ value: resourceFunction.id, disabled: false }),
        name: new FormControl({ value: resourceFunction.name, disabled: false }),
        description: new FormControl({ value: resourceFunction.description, disabled: false }),
        capacity: new FormControl({ value: resourceFunction.capacity, disabled: false }),
        timeAmount: new FormControl({ value: resourceFunction.timeAmount, disabled: false }),
        timeSortId: new FormControl({ value: resourceFunction.timeSortId || '', disabled: false }),
        isNew: new FormControl({ value: true, disabled: false }),
      });
    this.resourceFunctionLines.push(activeResourceFunctionFormGroup);
    this.activeResourceFunction = activeResourceFunctionFormGroup;
  }

  firststage = false;
  loading = false;
  saveItem() {
    this.loading = true;
    const updateThings = new Subject<void>();
    const updateResource = new Subject<void>();
    const addResourceFunctions = new Subject<void>();
    const deleteResourceFunctions = new Subject<void>();

    if (!this.activeResourceThing) {
      this.resourceThingFacade.addResourceThings([
        new ResourceThing({
          name: this.activeResourceThingGroup?.value.name,
        })
      ],
        (res) => {
          this.activeResourceThing = res[0];
          this.loading = false;
        }
      );
      return;
    }
    const removeResourceFunctions = this.removeResourceFunctions.filter(id => !this.addedResourceFunctions.some(af => af.id == id))
    if (removeResourceFunctions.length !== 0)
      this.resourceThingFacade.removeResourceFunction(removeResourceFunctions, () => deleteResourceFunctions.next());
    this.resourceThingFacade.updateResourceThings(
      [
        new ResourceThing({
          id: this.activeResourceThingGroup?.value.id,
          name: this.activeResourceThingGroup?.value.name,
        }),
      ],
      () => updateThings.next()
    );
    const funcs = this.getThingResourceFunctionLineGroups().map((group) =>
      this.getResourceFunction(group.value)
    ).filter(f => !this.removeResourceFunctions.some(id => id === f.id));
    this.resourceThingFacade.updateResourceFunctions(funcs, () =>
      updateResource.next()
    );
    const addedResourceFunctions = this.addedResourceFunctions.filter(f => !this.removeResourceFunctions.some(id => id === f.id));
    if (addedResourceFunctions.length !== 0)
      this.resourceThingFacade.addResourceFunctions(addedResourceFunctions, () => addResourceFunctions.next());
    combineLatest([updateThings, updateResource, addResourceFunctions, deleteResourceFunctions])
      .pipe(first())
      .subscribe(() => {
        this.loading = false;
        this.dialogRef.close();
      });
  }

  cancelFunc() {
    this.activeResourceFunction?.reset(
      this.resourceFunctions.find(
        (f) => f.id === this.activeResourceFunction?.value.id
      )
    );
    this.activeResourceFunction = undefined;
  }

  deleteFunc(fg: FormGroup) {
    const index = this.removeResourceFunctions.indexOf(fg.value.id);
    if (index > -1)
      this.removeResourceFunctions.splice(index, 1);
    else
      this.removeResourceFunctions.push(fg.value.id);
  }

  getThingTitle(fg: FormGroup) {
    return fg.value.name;
  }

  getThingTitleControl() {
    return this.activeResourceThingGroup?.controls['name'] as FormControl;
  }

  getThingResourceFunctionLines(fg: FormGroup) {
    return (fg.controls['resourceFunctionLines'] as FormArray).controls;
  }
  getThingResourceFunctionLineGroups(): FormGroup[] {
    let lineGroups = (
      this.activeResourceThingGroup?.controls[
      'resourceFunctionLines'
      ] as FormArray
    ).controls as FormGroup[];
    const addedResourceFunctions = lineGroups.filter(x => x.value.isNew).map(x => x.value);
    addedResourceFunctions.forEach(x => x.resourceThingId = this.activeResourceThingGroup?.value.id || '');
    this.addedResourceFunctions = addedResourceFunctions;
    lineGroups = lineGroups.filter(x => !x.value.isNew);
    return lineGroups;
  }

  isActiveFunc(fg: FormGroup) {
    return (
      fg.value.id === this.activeResourceFunction?.value.id
    );
  }

  isDeleteFunc(fg: FormGroup) {
    return this.removeResourceFunctions.includes(fg.value.id);
  }

  getFunctionTitleControl(fg: FormGroup) {
    return fg.controls['name'] as FormControl;
  }

  getFunctionDescriptionControl(fg: FormGroup) {
    return fg.controls['description'] as FormControl;
  }

  getFunctionTimeAmount(fg: FormGroup) {
    return fg.controls['timeAmount'] as FormControl;
  }

  getFunctionTimeSortId(fg: FormGroup) {
    return fg.controls['timeSortId'] as FormControl;
  }

  getFunctionCapacity(fg: FormGroup) {
    return fg.controls['capacity'] as FormControl;
  }

  getTotalFunctionTimeAmount(fg: FormGroup) {
    return (((fg.controls['capacity'] as FormControl).value as number) *
      (fg.controls['timeAmount'] as FormControl).value) as number;
  }

  getTimeSortName(fg: FormGroup) {
    this.timeSorts$.value.find(
      (ts) => ts.id === (fg.controls['timeSortId'] as FormControl).value
    )?.sort;
  }

  canDeactivate() {
    return false;
  }

  showNavigationPopup() {
    return of(true);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  close() {
    if (!this.activeResourceThingGroup?.pristine)
      this.popup.openPrevenNavigationPopup((close) => {
        if (close) this.dialogRef.close();
      });
    else this.dialogRef.close();
  }
}
