import { CdkDrag, DragDropModule } from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LuxonDateModule } from '@angular/material-luxon-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import {
  MatCommonModule,
  MatNativeDateModule,
  MatOptionModule,
} from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import {
  MAT_TOOLTIP_DEFAULT_OPTIONS,
  MatTooltipDefaultOptions,
  MatTooltipModule,
} from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ColorPickerModule } from 'ngx-color-picker';
import { MatProgressBarModule } from '@angular/material/progress-bar';

import {
  ActionBarComponent,
  ContextMenuComponent,
  DashboardComponent,
  LoaderComponent,
  UserInitialsComponent,
} from 'processdelight-angular-components';
import * as fromCoreFeature from './core/store/core.feature';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatListModule } from '@angular/material/list';

export const MatTooltipOption: MatTooltipDefaultOptions = {
  showDelay: 0,
  hideDelay: 0,
  touchGestures: 'auto',
  position: 'before',
  touchendHideDelay: 0,
  disableTooltipInteractivity: true,
};

@NgModule({
  declarations: [],
  providers: [
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: MatTooltipOption },
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatIconModule,
    MatNativeDateModule,
    StoreModule.forFeature(
      fromCoreFeature.featureKey,
      fromCoreFeature.reducers
    ),
    EffectsModule.forFeature(fromCoreFeature.effects),
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatSelectModule,
    MatExpansionModule,
    MatSnackBarModule,
    MatInputModule,
    MatTableModule,
    MatTabsModule,
    MatOptionModule,
    MatCommonModule,
    UserInitialsComponent,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatDividerModule,
    MatCardModule,
    DashboardComponent,
    MatSlideToggleModule,
    MatStepperModule,
    MatButtonToggleModule,
    MatTreeModule,
    MatDatepickerModule,
    DragDropModule,
    CdkDrag,
    ScrollingModule,
    MatTooltipModule,
    LuxonDateModule,
    ActionBarComponent,
    LoaderComponent,
    ContextMenuComponent,
    MatDialogModule,
    ColorPickerModule,
    MatProgressBarModule,
    MatCheckboxModule,
  ],
  exports: [
    CommonModule,
    RouterModule,
    MatIconModule,
    MatNativeDateModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatSelectModule,
    MatExpansionModule,
    MatSnackBarModule,
    MatInputModule,
    MatTableModule,
    MatTabsModule,
    MatOptionModule,
    MatCommonModule,
    UserInitialsComponent,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatDividerModule,
    MatCardModule,
    DashboardComponent,
    MatSlideToggleModule,
    MatStepperModule,
    MatButtonToggleModule,
    MatTreeModule,
    MatDatepickerModule,
    DragDropModule,
    CdkDrag,
    ScrollingModule,
    MatTooltipModule,
    LuxonDateModule,
    ActionBarComponent,
    LoaderComponent,
    ContextMenuComponent,
    MatDialogModule,
    ColorPickerModule,
    MatProgressBarModule,
    MatCheckboxModule,
    MatListModule
  ],
})
export class CoreModule { }
