<div class="infocont">
  <div class="dialog-header">
    <span>{{activeResourceThing ? activeResourceThing.name : translations.newMachine}}</span>
    <div class="action-btns w-100 d-flex flex-row align-items-center justify-content-between">
      <div class="d-flex flex-row align-items-center gap-2">
        <button mat-flat-button (click)="saveItem()" [disabled]="activeResourceThingGroup?.invalid">{{translations.save}}</button>
        <mat-spinner class="smallspinner" *ngIf="loading"></mat-spinner>
        <button mat-flat-button (click)="close()">{{translations.cancel}}</button>
        <button *ngIf="activeResourceThing" mat-stroked-button color="primary"
          (click)="addNewFunction()">
          {{translations.addFunction}}
        </button>
      </div>

      <mat-icon class="dialog-close" (click)="close()">close</mat-icon>
    </div>
  </div>

  <div class="info">
    <div class="">
      <mat-form-field appearance="outline" class="betterinput">
        <mat-label>{{translations.name}}</mat-label>
        <input #machineName matInput [formControl]="getThingTitleControl()" />
      </mat-form-field>

      <div class="functions">
        <div class="title">
          <span>{{translations.functions}}</span>
        </div>
        <mat-divider></mat-divider>

        <mat-list>
          <ng-container *ngFor="let func of resourceFunctionLines">
            <mat-list-item (click)="selectFunc(func)" [class]="isDeleteFunc(func) ? 'deletefunc' : ''">
              <span matListItemTitle>{{ func.value.name }}</span>
              <span class="pristineIndic" *ngIf="!func.pristine"> ⬤ </span>
              <button mat-icon-button [matMenuTriggerFor]="menu" class="optionsBtn">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="deleteFunc(func);">
                  {{translations.delete}}
                </button>
              </mat-menu>
            </mat-list-item>
            <mat-divider></mat-divider>
          </ng-container>
        </mat-list>
      </div>
    </div>
    <ng-container *ngIf="activeResourceFunction">
      <mat-divider [vertical]="true"></mat-divider>
      <div class="active-resource-container d-flex flex-column gap-3 p-1">
        <mat-form-field appearance="outline" class="betterinput">
          <mat-label>{{translations.name}}</mat-label>
          <input appInputAutofocus matInput [formControl]="getFunctionTitleControl(activeResourceFunction)" [triggerFocus]="activeResourceFunction" />
        </mat-form-field>
        <mat-form-field appearance="outline" class="betterinput">
          <mat-label>{{translations.description}}</mat-label>
          <textarea matInput [formControl]="getFunctionDescriptionControl(activeResourceFunction)">
        </textarea>
        </mat-form-field>
        <div class="timecalc">
          <mat-form-field appearance="outline">
            <mat-label>{{translations.timeAmount}}</mat-label>
            <input matInput type="number" min="0" [formControl]="getFunctionTimeAmount(activeResourceFunction)" />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>{{translations.timeUnit}}</mat-label>
            <mat-select [formControl]="getFunctionTimeSortId(activeResourceFunction)">
              <mat-option [value]="timeSort.id" *ngFor="let timeSort of timeSorts$ | async">
                {{ timeSort.sort }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>{{translations.capacity}}</mat-label>
            <input matInput type="number" min="0" [formControl]="getFunctionCapacity(activeResourceFunction)" />
          </mat-form-field>
          <div class="d-flex align-items-center">
            <span>{{translations.totalTimespan}}</span>
            <span>{{ getTotalFunctionTimeAmount(activeResourceFunction) }}
              {{ getTimeSortName(activeResourceFunction) }}</span>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>