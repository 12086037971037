import { createAction, props } from '@ngrx/store';
import { ResourceThing } from '../../models/resource/resourceThing';
import { ResourceFunction } from '../../models/resource/resourceFunction';
import { ResourceUser } from '../../models/resource/resourceUser';
import { Resource } from '../../models/resource/resource';
import { AppConfig } from '../../models/app.config';
import { TimeSort } from '../../models/resource/timesort';
import { Filter } from 'processdelight-angular-components';
import { ActionBase } from 'processdelight-angular-components/lib/store/base/action-base.interface';

export const getInitialLoad = createAction(
  '[Tasks] Get initial load data',
  props<{
    callback?: () => void;
  }>()
);

export const getInitialLoadResolved = createAction(
  '[Tasks] Load initial parameters into state',
  props<{
    resources: Resource[];
    resourceThings: ResourceThing[];
    resourceFunctions: ResourceFunction[];
    resourceUsers: ResourceUser[];
  }>()
);

export const getAppConfig = createAction(
  '[AppConfig] Get AppConfig',
  props<{
    callback?: () => void;
  }>()
);

export const getAppConfigResolved = createAction(
  '[AppConfig] Get AppConfig resolved',
  props<{
    appconfig: AppConfig;
  }>()
);

export const updateAppConfig = createAction(
  '[AppConfig] Update AppConfig',
  props<{
    appConfig: AppConfig;
    callback?: () => void;
  }>()
);

export const updateAppConfigResolved = createAction(
  '[AppConfig] Update AppConfig resolved',
  props<{
    appconfig: AppConfig;
  }>()
);

export const getResources = createAction(
  '[Resource] Get Resources',
  props<{
    callback?: () => void;
  }>()
);

export const getResourcesResolved = createAction(
  '[Resource] Get Resources resolved',
  props<{
    resources: Resource[];
  }>()
);

export const addResources = createAction(
  '[Resource] Add Resources',
  props<{ resources: Resource[]; callback?: () => void }>()
);

export const addResourcesResolved = createAction(
  '[Resource] Add Resources resolved',
  props<{
    resources: Resource[];
  }>()
);

export const updateResourcesForTask = createAction(
  '[Resource] Update Resources for task',
  props<{ taskId: string; resources: Resource[]; callback?: () => void }>()
);

export const updateResourcesForTaskResolved = createAction(
  '[Resource] Update Resources for task resolved',
  props<{
    taskId: string;
    resources: Resource[];
  }>()
);

export const removeResources = createAction(
  '[Resource] Remove Resources',
  props<{ ids: string[]; callback?: () => void }>()
);

export const removeResourcesResolved = createAction(
  '[Resource] Remove Resources resolved',
  props<{
    ids: string[];
  }>()
);

export const getAllResourceThings = createAction(
  '[ResourceThing] Get all ResourceThings',
  props<{
    callback?: (resourceThings: ResourceThing[]) => void;
    errorCallback?: (error: any) => void;
  }>()
);

export const getAllResourceThingsResolved = createAction(
  '[ResourceThing] Get all ResourceThings resolved',
  props<{
    resourceThings: ResourceThing[];
  }>()
);

export const getResourceThings = createAction(
  '[ResourceThing] Get ResourceThings',
  props<{
    orderBy: string;
    direction: string;
    filters: Filter[];
    pageSize: number;
    page: number;
    resetPaging: boolean;
    callback?: (resourceThings: ResourceThing[]) => void;
    errorCallback?: (error: any) => void;
  }>()
);

export const getResourceThingsResolved = createAction(
  '[ResourceThing] Get ResourceThings resolved',
  props<{
    resourceThings: ResourceThing[];
    totalRecordCount: number;
    resetPaging: boolean;
  }>()
);

export const removeResourceThings = createAction(
  '[ResourceThing] Remove ResourceThing',
  props<{ ids: string[]; callback?: () => void }>()
);

export const removeResourceThingResolved = createAction(
  '[ResourceThing] Remove ResourceThing resolved',
  props<{ ids: string[] }>()
);

export const updateResourceThings = createAction(
  '[ResourceThings] Update ResourceThings',
  props<{ resourceThings: ResourceThing[]; callback?: () => void }>()
);

export const updateResourceThingsResolved = createAction(
  '[ResourceThings] Update ResourceThings resolved',
  props<{ updatedResourceThings: ResourceThing[] }>()
);

export const addResourceThings = createAction(
  '[ResourceThings] Add ResourceThings',
  props<{
    resourceThings: ResourceThing[];
    callback?: (addedResourceThings: ResourceThing[]) => void;
  }>()
);

export const addResourceThingsResolved = createAction(
  '[ResourceThings] Add ResourceThings resolved',
  props<{ addedResourceThings: ResourceThing[] }>()
);

export const getResourceFunctions = createAction(
  '[ResourceFunctions] Get ResourceFunctions',
  props<{
    callback?: () => void;
  }>()
);

export const getResourceFunctionsResolved = createAction(
  '[ResourceFunctions] Get ResourceFunctions resolved',
  props<{
    resourceFunctions: ResourceFunction[];
  }>()
);

export const removeResourceFunctions = createAction(
  '[ResourceFunctions] Remove ResourceFunctions',
  props<{ ids: string[]; callback?: () => void }>()
);

export const removeResourceFunctionsResolved = createAction(
  '[ResourceFunctions] Remove ResourceFunctions resolved',
  props<{ ids: string[] }>()
);

export const updateResourceFunctions = createAction(
  '[ResourceFunctions] Update ResourceFunctions',
  props<{ resourceFunctions: ResourceFunction[]; callback?: () => void }>()
);

export const updateResourceFunctionsResolved = createAction(
  '[ResourceFunctions] Update ResourceFunctions resolved',
  props<{ updatedResourceFunctions: ResourceFunction[] }>()
);

export const addResourceFunctions = createAction(
  '[ResourceFunctions] Add ResourceFunctions',
  props<{
    resourceFunctions: ResourceFunction[];
    callback?: () => void;
  }>()
);

export const addResourceFunctionsResolved = createAction(
  '[ResourceFunctions] Add ResourceFunctions resolved',
  props<{ addedResourceFunctions: ResourceFunction[] }>()
);

export const getResourceUsers = createAction(
  '[ResourceUser] Get Resource users',
  props<{ filters: Filter[], callback?: () => void }>()
);

export const getResourceUsersResolved = createAction(
  '[ResourceUser] Get Resource users resolved',
  props<{ resourceUsers: ResourceUser[] }>()
);

export const addResourceUsers = createAction(
  '[ResourceUser] Add Resource users',
  props<{ resourceUsers: ResourceUser[]; callback?: () => void }>()
);

export const addResourceUsersResolved = createAction(
  '[ResourceUser] Add Resource users resolved',
  props<{ resourceUsers: ResourceUser[] }>()
);

export const updateResourceUsers = createAction(
  '[ResourceUser] Update Resource users',
  props<{ resourceUsers: ResourceUser[]; callback?: () => void }>()
);

export const updateResourceUsersResolved = createAction(
  '[ResourceUser] Update Resource users resolved',
  props<{ resourceUsers: ResourceUser[] }>()
);

export const deleteResourceUsers = createAction(
  '[ResourceUser] Delete Resource users',
  props<
    {
      ids: string[];
    } & ActionBase<string[]>
  >()
);

export const deleteResourceUsersResolved = createAction(
  '[ResourceUser] Delete Resource users resolved',
  props<{ ids: string[] }>()
);

export const getTimeSorts = createAction(
  '[ResourceUser] Get Time sorts',
  props<{ callback?: () => void }>()
);

export const getTimeSortsResolved = createAction(
  '[ResourceUser] Get Time sorts resolved',
  props<{ timeSorts: TimeSort[] }>()
);
