import { Directive, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatInput } from '@angular/material/input';

@Directive({
  selector: '[appInputAutofocus]',
  standalone: true
})
export class InputAutofocusDirective implements OnInit, OnChanges{
  @Input() triggerFocus: any;
  constructor(private matInput: MatInput) { }

  ngOnInit() {
    setTimeout(() => this.matInput.focus());
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['triggerFocus']) {
      setTimeout(() => this.matInput.focus());
    }
  }
}
