import { Action, createReducer, on } from '@ngrx/store';
import { ResourceThing } from '../../models/resource/resourceThing';
import { AppState } from '../../../app.reducer';
import {
  getResourceThingsResolved,
  removeResourceThingResolved,
  updateResourceThingsResolved,
  addResourceThingsResolved,
  addResourceFunctionsResolved,
  getResourceFunctionsResolved,
  removeResourceFunctionsResolved,
  updateResourceFunctionsResolved,
  getResourceUsersResolved,
  addResourceUsersResolved,
  updateResourceUsersResolved,
  deleteResourceUsersResolved,
  getResourcesResolved,
  addResourcesResolved,
  updateResourcesForTaskResolved,
  removeResourcesResolved,
  getAppConfigResolved,
  updateAppConfigResolved,
  getInitialLoadResolved,
  getTimeSortsResolved,
} from './resource.actions';
import { ResourceFunction } from '../../models/resource/resourceFunction';
import { ResourceUser } from '../../models/resource/resourceUser';
import { Resource } from '../../models/resource/resource';
import { AppConfig } from '../../models/app.config';
import { TimeSort } from '../../models/resource/timesort';

export const featureSlice = 'resourceThing';

export interface State {
  appConfig?: AppConfig;
  resources: Resource[];
  allResourceThings: ResourceThing[];
  resourceThings: ResourceThing[];
  resourceFunctions: ResourceFunction[];
  resourceUsers: ResourceUser[];
  timeSorts: TimeSort[];
  pagingCookie: string;
  totalRecordCount: number;
}
const defaultState: State = {
  resources: [],
  allResourceThings: [],
  resourceThings: [],
  resourceFunctions: [],
  resourceUsers: [],
  timeSorts: [],
  pagingCookie: '',
  totalRecordCount: 0,
};

export function Reducer(state: State | undefined, action: Action) {
  return resourceReducer(state, action);
}

export const initialState: State = defaultState;

const getThings = (resourceThings: ResourceThing[], resourceFunctions: ResourceFunction[]) => [
  ...resourceThings.map((rt) => new ResourceThing({
    ...rt,
    resourceFunctions: resourceFunctions.filter(
      (rf) => rf.resourceThingId === rt.id
    ),
  })),
];

export const resourceReducer = createReducer(
  initialState,
  on(getAppConfigResolved, (state, { appconfig }) => ({
    ...state,
    appConfig: appconfig,
  })),
  on(getInitialLoadResolved, (state, { resources, resourceThings, resourceFunctions, resourceUsers }) => ({
    ...state,
    resources: resources,
    allResourceThings: resourceThings,
    resourceFunctions: resourceFunctions,
    resourceUsers: resourceUsers,
  })),
  on(updateAppConfigResolved, (state, { appconfig }) => ({
    ...state,
    appConfig: appconfig,
  })),
  on(getResourceThingsResolved, (state, { resourceThings, totalRecordCount, resetPaging }) => ({
    ...state,
    resourceThings: resetPaging 
      ? getThings([...resourceThings], state.resourceFunctions || []) 
      : getThings([...state.resourceThings, ...resourceThings], state.resourceFunctions || []),
    totalRecordCount,
  })),
  on(removeResourceThingResolved, (state, { ids }) => ({
    ...state,
    resourceThings:
      state.resourceThings?.filter(
        (rt) => !ids.includes(rt.id!)
      ) || [],
  })),
  on(updateResourceThingsResolved, (state, { updatedResourceThings }) => ({
    ...state,
    resourceThings: state.resourceThings?.map(
      (s) =>
        updatedResourceThings.find(
          (u) => s.id === u.id
        ) ?? s
    ),
  })),
  on(addResourceThingsResolved, (state, { addedResourceThings }) => ({
    ...state,
    resourceThings: state.resourceThings?.concat([...addedResourceThings]) 
      || addedResourceThings,
  })),
  on(getResourceFunctionsResolved, (state, { resourceFunctions }) => ({
    ...state,
    resourceThings: getThings(state.resourceThings || [], resourceFunctions),
    resourceFunctions: [...resourceFunctions],
  })),
  on(removeResourceFunctionsResolved, (state, { ids }) => ({
    ...state,
    resourceThings: getThings(
      state.resourceThings || [],
      state.resourceFunctions?.filter(
        (rf) => !ids.includes(rf.id!)
      ) || []
    ),
    resourceFunctions: state.resourceFunctions?.filter(
      (rf) => !ids.includes(rf.id!)
    ),
  })),
  on(updateResourceFunctionsResolved, (state, { updatedResourceFunctions }) => {
    const resourceFunctions = state.resourceFunctions?.map(
      (rf) => updatedResourceFunctions.find(
        (u) => rf.id === u.id
      ) ?? rf
    );
    return {
      ...state,
      resourceThings: getThings(state.resourceThings || [], resourceFunctions || []),
      resourceFunctions,
    };
  }),
  on(addResourceFunctionsResolved, (state, { addedResourceFunctions }) => ({
    ...state,
    resourceThings: getThings(
      state.resourceThings || [],
      addedResourceFunctions.concat([...(state.resourceFunctions || [])])
    ),
    resourceFunctions: addedResourceFunctions.concat([
      ...(state.resourceFunctions || []),
    ]),
  })),
  on(getResourceUsersResolved, (state, { resourceUsers }) => ({
    ...state,
    resourceUsers,
  })),
  on(addResourceUsersResolved, (state, { resourceUsers }) => ({
    ...state,
    resourceUsers: state.resourceUsers?.concat(resourceUsers) || resourceUsers,
  })),
  on(updateResourceUsersResolved, (state, { resourceUsers }) => ({
    ...state,
    resourceUsers:
      state.resourceUsers?.map(
        (ru) => resourceUsers.find(
          (u) => u.id === ru.id
        ) ?? ru
      ) || [],
  })),
  on(deleteResourceUsersResolved, (state, { ids }) => ({
    ...state,
    resourceUsers:
      state.resourceUsers?.filter(
        (u) => !ids.some((id) => id === u.id)
      ) || [],
  })),
  on(getResourcesResolved, (state, { resources }) => ({
    ...state,
    resources: resources,
  })),
  on(addResourcesResolved, (state, { resources }) => ({
    ...state,
    resources: state.resources?.concat(resources) || resources,
  })),
  on(updateResourcesForTaskResolved, (state, { taskId, resources }) => ({
    ...state,
    resources:
      state.resources?.filter((r) => r.taskId !== taskId).concat(resources) ||
      resources,
  })),
  on(removeResourcesResolved, (state, { ids }) => ({
    ...state,
    resources:
      state.resources?.filter(
        (r) => !ids.some((id) => id === r.id)
      ) || [],
  })),
  on(getTimeSortsResolved, (state, { timeSorts }) => ({
    ...state,
    timeSorts: timeSorts
  })),
);

export const resourceThingState = (state: AppState) =>
  state.coreFeature.resourceThing;
