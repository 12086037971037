import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DashboardComponent, Filter, GroupUser } from 'processdelight-angular-components';
import { map } from 'rxjs';
import { AppInfo } from '../models/resource/appinfo';
import { Resource } from '../models/resource/resource';
import { ResourceFunction } from '../models/resource/resourceFunction';
import { ResourceThing } from '../models/resource/resourceThing';
import { ResourceUser } from '../models/resource/resourceUser';
import { TimeSort } from '../models/resource/timesort';
import { LicenseInfo } from '../models/ishtar365/licenseinfo';
import { Task } from '../models/task/task';
import { Project } from '../models/task/project';
import { ProjectMVP } from '../models/task/projectMVP';
import { PublicHoliday } from '../models/ishtar365/publicHoliday';
import { WorkRegime } from '../models/ishtar365/workregime';
import { AppConfig } from '../models/app.config';
import { Status } from '../models/task/status';
import { camelcaseKeys } from '../helpers/object.functions';
import { FunctionsService } from './functions.service';
import { TaskRegistrationType } from '../models/task/registration-type';
import { ProjectType } from '../models/task/project-type';
import { TimeSortType } from '../enums/time-sort.type';

@Injectable({
  providedIn: 'root',
})
export class IshtarResourcePlanningService {
  private apiBase = `${location.origin}/web`;

  constructor(
    private httpClient: HttpClient,
    private functionsService: FunctionsService
  ) {}

  getLicense(tenantId: string) {
    return this.httpClient.post<LicenseInfo>(
      `${this.apiBase}/session/register?tenantId=${tenantId}`,
      {}
    );
  }

  sessionKeepAlive() {
    return this.httpClient.post(`${this.apiBase}/session/keepalive`, {});
  }

  getConfig() {
    return this.httpClient.get<AppConfig>(`${this.apiBase}/organization/config`)
      .pipe(map((c) => new AppConfig(camelcaseKeys(c))));
  }

  updateConfig(config: AppConfig) {
    return this.httpClient.post<AppConfig>(`${this.apiBase}/organization/config`, config)
      .pipe(map((c) => new AppConfig(camelcaseKeys(c))));
  }

  /*
  getAllLicenses() {
    return this.httpClient.get<UserLicenseInfo[]>(
      this.createApiEndpointUrl(`license/${this.msal.tenantId}/`)
    );
  }
  */

  getAppInfo() {
    return this.httpClient
      .get<AppInfo>(`${this.apiBase}/organization/app/Ishtar.Resource`)
      .pipe(map((info) => new AppInfo(camelcaseKeys(info))));
  }

  getUsers() {
    return this.httpClient.get<GroupUser[]>(`${this.apiBase}/organization/users`)
      .pipe(map((users) => users.map((u) => new GroupUser(camelcaseKeys(u)))));
  }

  getGroups() {
    return this.httpClient.get<GroupUser[]>(`${this.apiBase}/organization/groups`)
      .pipe(map((groups) => groups.map((g) => new GroupUser(camelcaseKeys(g)))));
  }

  getTranslations(lang: string) {
    return this.httpClient.get<any>(`${this.apiBase}/user/translations/${lang}`);
  }

  getTimeSorts() {
    return this.httpClient.get<TimeSort[]>(`${this.apiBase}/time-sort?filter=(type=${TimeSortType.Resource})`)
      .pipe(map((timeSorts) => timeSorts.map((ts) => new TimeSort(camelcaseKeys(ts)))));
  }

  getTaskRegistrationTypes() {
    return this.httpClient.get<TaskRegistrationType[]>(`${this.apiBase}/task/registration-type`)
      .pipe(map((registrationTypes) => registrationTypes.map((rt) => new TaskRegistrationType(camelcaseKeys(rt)))));
  }

  getProjectTypes() {
    return this.httpClient.get<ProjectType[]>(`${this.apiBase}/project-type`)
      .pipe(map((projectTypes) => projectTypes.map((pt) => new ProjectType(camelcaseKeys(pt)))));
  }

  getPublicHolidays(year: number) {
    return this.httpClient.get<PublicHoliday[]>(`${this.apiBase}/public-holiday/${year}`)
      .pipe(map((publicHolidays) => publicHolidays.map((ph) => new PublicHoliday(camelcaseKeys(ph)))));
  }

  getResources() {
    return this.httpClient.get<Resource[]>(`${this.apiBase}/resource`)
      .pipe(map((resources) => resources.map((r) => new Resource(camelcaseKeys(r)))));
  }

  addResources(resources: Resource[]) {
    return this.httpClient.post<Resource[]>(`${this.apiBase}/resource`, resources)
      .pipe(map((resources) => resources.map((r) => new Resource(camelcaseKeys(r)))));
  }

  updateResourcesForTask(taskId: string, resources: Resource[]) {
    return this.httpClient.patch<Resource[]>(`${this.apiBase}/resource/${taskId}`, resources)
      .pipe(map((resources) => resources.map((r) => new Resource(camelcaseKeys(r)))));
  }

  deleteResources(ids: string[]) {
    return this.httpClient.delete<string[]>(`${this.apiBase}/resource`, { body: ids });
  }

  getAllResourceThings() {
    return this.httpClient.get<ResourceThing[]>(`${this.apiBase}/resource-thing/all`)
      .pipe(map((resourceThings) => resourceThings.map((rt) => new ResourceThing(rt))));
  }

  getResourceThings(
    orderBy: string,
    direction: string,
    filters: Filter[],
    pageSize: number,
    page: number
  ) {
    const filter = DashboardComponent.createFilterString(filters);
    let url = `${this.apiBase}/resource-thing?orderBy=${orderBy}&direction=${direction}&pageSize=${pageSize}&page=${page}`;

    if (filter !== '') {
      url += `&filter=${filter}`;
    }

    return this.httpClient.get<{ result: ResourceThing[]; totalRecordCount: number; }>(url);
  }

  addResourceThings(resourceThings: ResourceThing[]) {
    return this.httpClient.post<ResourceThing[]>(`${this.apiBase}/resource-thing`, resourceThings)
      .pipe(map((resourceThings) => resourceThings.map((rt) => new ResourceThing(camelcaseKeys(rt)))));
  }

  updateResourceThings(resourceThings: ResourceThing[]) {
    return this.httpClient.patch<ResourceThing[]>(`${this.apiBase}/resource-thing`, resourceThings)
      .pipe(map((resourceThings) => resourceThings.map((rt) => new ResourceThing(camelcaseKeys(rt)))));
  }

  removeResourceThings(ids: string[]) {
    return this.httpClient.delete<string[]>(`${this.apiBase}/resource-thing`, { body: ids });
  }

  getResourceFunctions() {
    return this.httpClient.get<ResourceFunction[]>(`${this.apiBase}/resource-function`)
      .pipe(map((resourceFunctions) => resourceFunctions.map((rf) => new ResourceFunction(camelcaseKeys(rf)))));
  }

  addResourceFunctions(resourceFunctions: ResourceFunction[]) {
    return this.httpClient.post<ResourceFunction[]>(`${this.apiBase}/resource-function`, resourceFunctions)
      .pipe(map((resourceFunctions) => resourceFunctions.map((rf) => new ResourceFunction(camelcaseKeys(rf)))));
  }

  updateResourceFunctions(resourceFunctions: ResourceFunction[]) {
    return this.httpClient.patch<ResourceFunction[]>(`${this.apiBase}/resource-function`, resourceFunctions)
      .pipe(map((resourceFunctions) => resourceFunctions.map((rf) => new ResourceFunction(camelcaseKeys(rf)))));
  }

  removeResourceFunctions(ids: string[]) {
    return this.httpClient.delete<string[]>(`${this.apiBase}/resource-function`, { body: ids });
  }

  getResourceUsers(filters: Filter[]) {
    const filter = DashboardComponent.createFilterString(filters);
    let url = `${this.apiBase}/resource-user?`;

    if (filter !== '') {
      url += `filter=${filter}`;
    }

    return this.httpClient.get<ResourceUser[]>(url)
      .pipe(map((resourceUsers) => resourceUsers.map((ru) => new ResourceUser(camelcaseKeys(ru)))));
  }

  addResourceUsers(resourceUsers: ResourceUser[]) {
    return this.httpClient.post<ResourceUser[]>(`${this.apiBase}/resource-user`, resourceUsers)
      .pipe(map((resourceUsers) => resourceUsers.map((ru) => new ResourceUser(camelcaseKeys(ru)))));
  }

  updateResourceUsers(resourceUsers: ResourceUser[]) {
    return this.httpClient.patch<ResourceUser[]>(`${this.apiBase}/resource-user`, resourceUsers)
      .pipe(map((resourceUsers) => resourceUsers.map((ru) => new ResourceUser(camelcaseKeys(ru)))));
  }

  deleteResourceUsers(ids: string[]) {
    return this.httpClient.delete<string[]>(`${this.apiBase}/resource-user`, { body: ids });
  }

  getTasks() {
    return this.httpClient.get<Task[]>(`${this.apiBase}/task`)
      .pipe(map((tasks) => tasks.map((t) => new Task(camelcaseKeys(t)))));
  }

  getProjects() {
    return this.httpClient.get<Project[]>(`${this.apiBase}/project`)
      .pipe(map((projects) => projects.map((p) => new Project(camelcaseKeys(p)))));
  }

  getProjectMVPs() {
    return this.httpClient.get<ProjectMVP[]>(`${this.apiBase}/project-mvp`)
      .pipe(map((mvps) => mvps.map((mvp) => new ProjectMVP(camelcaseKeys(mvp)))));
  }

  getWorkRegimes() {
    return this.httpClient.get<WorkRegime[]>(`${this.apiBase}/work-regime`)
      .pipe(map((workRegimes) => workRegimes.map((wr) => new WorkRegime(camelcaseKeys(wr)))));
  }

  getTypes() {
    return this.httpClient.get<{
      projectTypes: ProjectType[];
      registrationTypes: TaskRegistrationType[];
      taskUsers: GroupUser[];
      resourceUsers: GroupUser[];
      statusses: Status[];
      timeSorts: TimeSort[];
    }>(`${this.apiBase}/resource/types`)
      .pipe(map((types) => ({
        projectTypes: types.projectTypes
          .map((pt) => new ProjectType(camelcaseKeys(pt))),
        registrationTypes: types.registrationTypes
          .map((rt) => new TaskRegistrationType(camelcaseKeys(rt))),
        taskUsers: types.taskUsers
          .map((tu) => new GroupUser(camelcaseKeys(tu))),
        resourceUsers: types.resourceUsers
          .map((ru) => new GroupUser(camelcaseKeys(ru))),
        statusses: types.statusses
          .map((s) => new Status(camelcaseKeys(s))),
        timeSorts: types.timeSorts
          .map((ts) => new TimeSort(camelcaseKeys(ts))),
      })));
  }

  getResourceCalculation(assignedToMe: boolean, mockDate?: string) {
    // TODO: add mockdate to headers
    /*
    const headers = {
      mockDate: mockDate
    };
    */
    return this.httpClient.get<{
      projects: Project[];
      tasks: Task[];
    }>(`${this.apiBase}/resource/calculation?assignedToMe=${assignedToMe}`)
      .pipe(map((result) => ({
        tasks: result.tasks.map((t) => new Task(camelcaseKeys(t))),
        projects: result.projects.map((p) => new Project(camelcaseKeys(p))),
      })));
  }

  getInitialLoad() {
    return this.httpClient.get<{
      resources: Resource[];
      resourceThings: ResourceThing[];
      resourceFunctions: ResourceFunction[];
      resourceUsers: ResourceUser[];
    }>(`${this.apiBase}/resource/initial-load`)
      .pipe(map((result) => ({
        resources: result.resources
          .map((r) => new Resource(camelcaseKeys(r))),
        resourceThings: result.resourceThings
          .map((rt) => new ResourceThing(camelcaseKeys(rt))),
        resourceFunctions: result.resourceFunctions
          .map((rf) => new ResourceFunction(camelcaseKeys(rf))),
        resourceUsers: result.resourceUsers
          .map((ru) => new ResourceUser(camelcaseKeys(ru))),
      })));
  }
}
